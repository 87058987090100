<template>
  <div class="contents register userInfo">
    <!-- <div class="title flexB">
    </div> -->
    <section class="box one">
      <h2>단어 관리</h2>

      <div class="inputBox word">
        <div class="flex">
          <p>
            카테고리
            <span>*</span>
          </p>

          <el-select v-model="category">
            <el-option
              v-for="(item, i) in categories"
              :key="i"
              :value="item"
              :label="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex">
          <p>
            품사
            <span>*</span>
          </p>
          <el-select v-model="parts">
            <el-option label="명사" value="명사"></el-option>
            <el-option label="대명사" value="대명사"></el-option>
            <el-option label="수사" value="수사"></el-option>
            <el-option label="형용사" value="형용사"></el-option>
            <el-option label="동사" value="동사"></el-option>
            <el-option label="관형사" value="관형사"></el-option>
            <el-option label="부사" value="부사"></el-option>
            <el-option label="조사" value="조사"></el-option>
            <el-option label="감탄사" value="감탄사"></el-option>
          </el-select>
        </div>
        <div class="flex">
          <p>
            한글 단어
            <span>*</span>
          </p>
          <input type="text" v-model="word" />
        </div>
        <div class="flex">
          <p>
            발음
            <span>*</span>
          </p>
          <input type="text" v-model="pronounce" />
        </div>
        <div class="flex">
          <p>
            영어
            <span>*</span>
          </p>
          <input type="text" v-model="word_en" />
        </div>

        <div class="flex">
          <p>일본어</p>
          <input type="text" v-model="word_jp" />
        </div>
        <div class="flex">
          <p>중국어</p>
          <input type="text" v-model="word_cn" />
        </div>
        <div class="flex">
          <p>스페인어</p>
          <input type="text" v-model="word_es" />
        </div>
        <div class="flex">
          <p>
            예문
            <span>*</span>
          </p>
          <button class="basic" @click="handleModal(null, null)">
            추가하기
          </button>
        </div>
        <div class="flex double">
          <table class="wordTable">
            <colgroup>
              <col style="width: 5%" />
              <col style="width: 45%" />
              <col style="width: 45%" />
              <col style="width: 5%" />
            </colgroup>
            <tr v-for="(item, i) in description" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.desc_kr }}</td>
              <td>
                <p><span class="en">영어</span> {{ item.desc_en }}</p>
                <p><span class="jp">일본어</span> {{ item.desc_jp }}</p>
                <p><span class="cn">중국어</span> {{ item.desc_cn }}</p>
                <p><span class="es">스페인어</span> {{ item.desc_es }}</p>
              </td>

              <td class="btnTd">
                <el-dropdown @command="handleCommand">
                  <span class="material-icons-round"> more_horiz </span>
                  <el-dropdown-menu slot="dropdown" class="dropMenu">
                    <el-dropdown-item
                      :command="{ type: 'edit', item: item, idx: i }"
                      >수정</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ type: 'delete', idx: i }"
                      >삭제</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
              <!-- <td class="center">
                <button class="line small" @click="handleModal(item, i)">
                  수정</button
                ><button class="delete small" @click="descItemDelete(i)">
                  삭제
                </button>
              </td> -->
            </tr>
          </table>
        </div>
        <!-- <div>
          <editor></editor>
        </div> -->
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </section>

    <div class="buttonWrap">
      <router-link to="/admin/word/manage">
        <span class="material-icons-round"> keyboard_backspace </span
        >목록</router-link
      >
    </div>

    <div class="modal" v-if="modalVisible">
      <div class="modal_bg" @click="close"></div>
      <div class="modal_body">
        <div class="modal_header">
          <p>예문 추가하기</p>
          <button @click="close">
            <span class="material-icons-round"> clear </span>
          </button>
        </div>
        <div class="modal_content description">
          <p>한글</p>
          <textarea v-model="desc_kr" placeholder="한글" />
          <p>영어</p>
          <textarea v-model="desc_en" placeholder="영어" />
          <p>일본어</p>
          <textarea v-model="desc_jp" placeholder="일본어" />
          <p>중국어</p>
          <textarea v-model="desc_cn" placeholder="중국어" />
          <p>스페인어</p>
          <textarea v-model="desc_es" placeholder="스페인어" />
          <button class="primary" @click="handleDescription">추가</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import category from "@/config/wordCategory.json";
// import editor from "@/components/Editor";
import { createWord, fetchWord, updateWord, removeWord } from "@/api/admin";
export default {
  // components: {
  //   editor,
  // },
  data() {
    return {
      editMode: false,
      moment: moment,
      word: "",
      word_jp: "",
      word_en: "",
      word_cn: "",
      word_es: "",
      desc_kr: "",
      desc_jp: "",
      desc_en: "",
      desc_cn: "",
      desc_es: "",
      pronounce: "",
      description: [],

      index: null,

      parts: "",
      category: "",
      updatedAt: "",
      createdAt: "",

      categories: "",
      modalVisible: false,
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "2");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.wordId = this.$route.query.id;
      this.getWord();
    }
    this.categories = category.categories;
  },
  methods: {
    getWord() {
      fetchWord(this.wordId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.word = res.data.data.word;
          this.word_jp = res.data.data.word_jp;
          this.word_en = res.data.data.word_en;
          this.word_cn = res.data.data.word_cn;
          this.word_es = res.data.data.word_es;
          this.description = res.data.data.description;
          this.pronounce = res.data.data.pronounce;
          this.parts = res.data.data.parts;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },

    handleModal(data, index) {
      console.log("handleModal", data, index);

      if (data != null && index != null) {
        this.desc_kr = data.desc_kr;
        this.desc_en = data.desc_en;
        this.desc_jp = data.desc_jp;
        this.desc_cn = data.desc_cn;
        this.desc_es = data.desc_es;
        this.index = index;
      }
      this.modalVisible = !this.modalVisible;
    },
    close() {
      this.index = null;
      this.desc_kr = "";
      this.desc_en = "";
      this.desc_jp = "";
      this.desc_cn = "";
      this.desc_es = "";
      this.modalVisible = false;
    },
    handleDescription() {
      if (this.desc_kr == "") {
        return alert("한글 예문을 입력해 주세요.");
      }

      let object = {
        desc_kr: this.desc_kr,
        desc_en: this.desc_en,
        desc_jp: this.desc_jp,
        desc_cn: this.desc_cn,
        desc_es: this.desc_es,
      };
      if (this.index != null) {
        this.description.splice(this.index, 1, object);
      } else {
        this.description.push(object);
      }
      console.log("description", this.description);
      this.close();
    },
    descItemDelete(index) {
      this.description.splice(index, 1);
    },

    handleDelete() {
      let result = confirm("삭제 하시겠습니까?");
      if (!result) {
        return;
      }
      removeWord(this.wordId).then((res) => {
        if (res.data.status == 200) {
          alert("단어 정보가 삭제 되었습니다.");
          this.$router.replace("/admin/word/manage");
        }
      });
    },
    submit() {
      if (this.category == "") {
        return alert("카테고리를 선택해 주세요.");
      } else if (this.parts == "") {
        return alert("품사를 선택해 주세요.");
      } else if (this.word == "") {
        return alert("한글 단어를 입력해 주세요.");
      }
      let data = {
        category: this.category,
        wordId: this.wordId,
        word: this.word,
        word_jp: this.word_jp,
        word_en: this.word_en,
        word_cn: this.word_cn,
        word_es: this.word_es,
        description: this.description,
        pronounce: this.pronounce,
        parts: this.parts,
      };

      if (this.editMode) {
        updateWord(data).then((res) => {
          if (res.data.status == 200) {
            alert("단어 정보가 반영 되었습니다.");
            this.$router.push("/admin/word/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        createWord(data).then((res) => {
          if (res.data.status == 200) {
            alert("단어 등록이 완료 되었습니다.");
            this.$router.push("/admin/word/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
    handleCommand(command) {
      if (command.type === "edit") {
        this.handleModal(command.item, command.idx);
      } else {
        this.descItemDelete(command.idx);
      }
      console.log("command >>>", command);
    },
  },
};
</script>
<style lang="scss" scoped>
textarea {
  width: 100% !important;
}
</style>
